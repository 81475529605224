import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { postData } from '@components/_api';
import { Angebot } from '../../interfaces';
import { useDebounce } from '@components/search/useDebounce';

const SearchBox = styled(Paper)(
  () => `
  padding: 2px 4px;
  display: flex;
  align-items: center;
`
);

export default function Search({
  updateData,
  reset,
  setIsSearch,
  token,
}: {
  updateData: (data: Angebot[]) => void;
  reset: (reload: boolean) => void;
  setIsSearch: (isSearch: boolean) => void;
  token: string;
}) {
  const [searchBoxContent, setSearchBoxContent] = useState('');
  const [value, setValue] = useState('');
  const searchInput = React.useRef<HTMLInputElement | null>(null);

  const inputHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchBoxContent(event.target.value);

    if (event.target.value.length > 0) setValue(event.target.value);
    if (event.target.value.length === 0) {
      reset(true);
    }
  };

  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    searchInput.current?.focus();
    const json = {
      skip: 0,
      take: 0,
      search: debouncedValue,
      sortOrders: [{ property: 'ort', ascending: true }],
    };

    if (debouncedValue.length > 0)
      postData('AngeboteAuth/get', json, token).then((resp) => {
        if (resp.status === 200) {
          if (resp.data !== 0) {
            updateData(resp.data);
          } else {
            updateData([]);
          }
        }
      });

    setIsSearch(true);
  }, [debouncedValue]);

  return (
    <>
      <SearchBox sx={{ maxWidth: '250px' }}>
        <InputBase
          placeholder='Suche'
          inputProps={{ 'aria-label': 'suche' }}
          onChange={(e) => inputHandler(e)}
          value={searchBoxContent}
          inputRef={searchInput}
        />
        <IconButton
          onClick={() => {
            setSearchBoxContent('');
            reset(true);
          }}
          aria-label='clear'
        >
          <ClearIcon />
        </IconButton>
      </SearchBox>
    </>
  );
}
