interface Wert {
  anmerkung: string;
  bedingung1: string;
  bedingung2: string;
  infotext: string;
  wert: string;
  werteliste: string;
  data: string[];
}

interface Werte {
  length: number;
  filter(arg0: (value: Wert) => boolean): Werte;
  forEach(arg0: (kat1: Wert) => void): unknown;
  id: BigInteger;
  wert: Wert;
}

export interface Kat_Was_und_WasDetails {
  [key: string]: string[];
}

const kat_was_details = [
  'Beratung',
  'Selbsthilfe',
  'Psychiatrische Behandlung',
  'Fachärtze',
  'Psychotherapeutische Behandlung',
  'Weitere Behandlungsverfahren',
  'Psychiatrische Reha',
  'Weiterbildung',
  'Freizeit- und Arbeitsangebote',
  'Wohnen',
];

export const KAT_NICE_NAMES: Record<string, string> = {
  kat_Was: 'Kategorien',
  kat_Konstellationen: 'Konstellationen',
  kat_Orte: 'Orte',
  kat_Zielgruppe: 'Zielgruppe',
  kat_Barrieren: 'Zugänglichkeit',
  kat_Kosten: 'Kosten',
  kat_Themen: 'Themen',
  kat_Sprachen: 'Sprache',
  //   Kat_Häufigkeit: 'Häufigkeit',
  //   Kat_PersonAlter: 'Alter',
  //   Kat_PersonRolleDetail: 'Personen Rollen Details',
  //   Kat_PersonRollen: 'Rolle',
};

// The two APIs angebotedoc/werte and angebotedoc/filter have different names for the categories:
export const kats_translations: Record<string, string> = {
  /* category name in "Werte"-Endpoint :  category name in "filter"-Endpoint */
  Kat_Was: 'kat_Was',
  Kat_Zielgruppe: 'kat_Zielgruppe',
  Kat_Geschlechter: 'kat_Geschlechter',
  Kat_Konstellation: 'kat_Konstellationen',
  Kat_Kosten: 'kat_Kosten',
  Kat_PersonAlter: 'kat_PersonAlter',
  Kat_PersonRolleDetail: 'kat_PersonRolleDetail',
  Kat_PersonRollen: 'kat_PersonRollen',
  Kat_Ort: 'kat_Orte',
  Kat_Themen: 'kat_Themen',
  Kat_WasDetail: 'kat_WasDetails',
  Kat_Barriere: 'kat_Barrieren',
  Sprache: 'kat_Sprachen',
};

/** Build initial Filter Options **/
function buildKats(data: Werte) {
  let werte = data;

  const kats: { [id: string]: string[] } = {};
  const kat_Was_und_WasDetails: Kat_Was_und_WasDetails = {};

  const kats_array = Object.keys(kats_translations);

  let katWithInfoText: { wert: string; infotext: string; anmerkung: string }[] =
    [];

  kats_array.forEach((kat: string) => {
    let kat_arr_temp: Werte = werte.filter((value) => {
      return kat === value.werteliste;
    });
    let kat_temp2: string[] = [];

    kat_arr_temp.forEach((kat1: Wert) => {
      kat_temp2.push(kat1.wert);
      katWithInfoText.push({
        wert: kat1.wert,
        infotext: kat1.infotext,
        anmerkung: kat1.anmerkung,
      });
    });
    kats[kat] = kat_temp2;
  });

  /*   let populated_kats = kats.filter((kat) => {
      return kat.length > 0;
    }); */

  /* in werte suchen:
  werteliste = "Kat_WasDetails"; wert = kat_was_details */
  kat_was_details.forEach((kat: string, i_kat: number) => {
    let details_for_this_kat: string[] = [];
    let details = werte.filter((value) => {
      return value.werteliste === 'Kat_WasDetail';
    });
    details.forEach((detail) => {
      if (detail.bedingung1 === kat) {
        details_for_this_kat.push(detail.wert);
      }
      kat_Was_und_WasDetails[kat] = details_for_this_kat;
    });
  });

  return [kats, kat_Was_und_WasDetails];
}

export { buildKats };
