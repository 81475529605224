import { getData } from '@components/_api';
import React, {
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import BackBtn from '../BackBtn';
import { empty_data } from './empty_einrichtung';

import {
  EditableAngebotInputObject,
  OpeningHours,
  Person,
} from '@components/../interfaces';
import SpeichernBtn from '@components/SpeichernBtn';

import MANeueEinrichtungTop from './components/MANeueEinrichtungTop';
import { topFormFields } from './components/topFormFields';

import MultipleSelect from '@components/dashboard-ma/MAEinrichtung/MultipleSelect';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  SelectChangeEvent,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Box1,
  Box2,
} from '@components/dashboard-ma/MAEinrichtung/MAEinrichtung-Styles';
import Zustaendige from '@components/dashboard-ma/components/zustaendige/Zustaendige';

import { Header } from '@components/dashboard-ma/components/Header';
import { Form, Formik } from 'formik';
import { useSnackbarContext } from '../../context/SnackBarContext';
import { TraegerProvider } from '../../context/TraegerContext';
import useGetCategories from '../../hooks/useGetCategories';
import { isObjectKey } from '../../utils/lib';
import { validation } from '../../utils/topEinrichtungValidation';
import {
  KAT_NICE_NAMES,
  kats_translations,
} from './MAEinrichtung/filter-categories';
import OpeningHoursPicker from './components/OpeningHoursPicker';
import TraegerWrap from './components/Traeger/TraegerWrap';

export interface TransformedOpeningHours {
  dayOfWeek: number;
  openings: OpeningHours[];
}

type KatType = {
  [key: string]: boolean;
};

export type FormValues = Partial<EditableAngebotInputObject> &
  Partial<{
    postfachPLZ: string;
    isWebsiteUnavailable: boolean;
    kats: Record<string, KatType | string[]>;
  }>;

export default function MAEinrichtung({ token }: { token: string }) {
  const { guid }: { guid: string } = useParams();
  const snackbarctx = useSnackbarContext();
  const formRef = useRef<HTMLFormElement>(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<EditableAngebotInputObject>(empty_data);
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const [initialOpeningHours, setInitialOpeningHours] = useState<
    TransformedOpeningHours[]
  >([]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const isAlwaysOpen = data['einsGeoeffnetDurchgehend'];
  const isOpenAppointment = data['einsGeoeffnetNachVereinbarung'];

  const { kats, katWasUndWasDetails } = useGetCategories();

  useEffect(() => {
    if (guid && !data?.guid) {
      setLoading(true);
      // only set the data from API if it is empty
      getData('AngeboteAuth/angebotuser/' + guid, token)
        .then((resp) => {
          if (resp.status === 200) {
            setData(resp.data);

            // transform opening hours data return from API to a more usable format for form/formik
            const transformedData: TransformedOpeningHours[] | undefined =
              resp.data?.openingHours &&
              resp.data.openingHours.reduce(
                (result: TransformedOpeningHours[], item: OpeningHours) => {
                  const existingItem = result.find(
                    (x) => x.dayOfWeek === item.dayOfWeek
                  );
                  const opening = {
                    open: item.open,
                    close: item.close,
                    dayOfWeek: item.dayOfWeek,
                  };

                  if (existingItem) {
                    existingItem.openings.push(opening);
                  } else {
                    result.push({
                      dayOfWeek: item.dayOfWeek,
                      openings: [opening],
                    });
                  }

                  return result;
                },
                []
              );

            function getTime(weekIndex: number, indexInOpening: number) {
              return transformedData?.find(
                ({ dayOfWeek }) => dayOfWeek === weekIndex
              )?.openings[indexInOpening];
            }

            const initialOpeningHours = () =>
              [
                ...[...Array(7)].map((_, i) => {
                  const index = i + 1;
                  return {
                    dayOfWeek: index,
                    openings: [
                      {
                        open: getTime(index, 0)?.open ?? '',
                        close: getTime(index, 0)?.close ?? '',
                      },
                      {
                        open: getTime(index, 1)?.open ?? '',
                        close: getTime(index, 1)?.close ?? '',
                      },
                    ],
                  };
                }),
              ] as TransformedOpeningHours[];

            setInitialOpeningHours(initialOpeningHours());
          } else {
            /*TODO : Error Handling */
            return;
          }
        })
        .finally(() => setLoading(false));
    } else {
      // only set initial opening hours if it is empty
      initialOpeningHours.length === 0 &&
        !loading &&
        setInitialOpeningHours(
          [...Array(7)].map((_, i) => {
            const index = i + 1;
            return {
              dayOfWeek: index,
              openings: [
                {
                  open: '',
                  close: '',
                },
                {
                  open: '',
                  close: '',
                },
              ],
            };
          }) as TransformedOpeningHours[]
        );
      setLoading(false);
    }
  }, [guid, data?.guid, initialOpeningHours.length]);

  const updateData = (feld: { [name: string]: string | Person[] }) => {
    setData({ ...data, ...feld });
  };

  const handleZustaendigerChange = (persons: Person[]): void => {
    persons.map((p) => (p.lfNrAngebote = data.autoLfNr));
    updateData({ angebotePos: persons });
  };

  const handleTopChange = (
    ev: React.ChangeEvent | SelectChangeEvent<string>,
    name: string
  ): void => {
    // @ts-ignore
    let value = ev.target.value.toString();

    if (name === 'plz') value = value.trim();

    updateData({
      [name]: value,
    });
  };

  // get maximum checkbox count per category from localStorage
  const multifieldcounts = localStorage.getItem('multifieldcounts');
  const checkBoxFieldCounts: Record<string, number> = multifieldcounts
    ? JSON.parse(multifieldcounts)
    : {};

  // create initial opening hours data for formik
  const formikInitialValues = () => {
    const result: FormValues = {};
    topFormFields.forEach(({ name }) => {
      const value = data[name as keyof typeof data];

      if (value !== undefined) {
        // @ts-ignore
        result[name] = value;
      }
    });
    return {
      ...result,
      isWebsiteUnavailable: data['isWebsiteUnavailable'] ?? false,
      neueKlientenApi: data['neueKlientenApi'],
      einsGeoeffnetDurchgehend: isAlwaysOpen,
      einsKeinTraeger: data['einsKeinTraeger'],
      einsDeutschlandweit: data['einsDeutschlandweit'],
      einsGeoeffnetNachVereinbarung: isOpenAppointment,
      validierung: data['validierung'],
    };
  };

  const katWasDetailsInitialValues = () => {
    const kat_was_details: string[] = [];
    kats?.Kat_WasDetail?.forEach((key) => {
      data.kat_WasDetails?.forEach((item) => {
        if (item.name === key && !kat_was_details.includes(key)) {
          kat_was_details.push(key);
        }
      });
    });
    return kat_was_details;
  };

  const checkBoxKatsInitialValues = () => {
    const checkboxKats: Record<string, Record<string, boolean>> = {};

    // Iterate through keys in kat
    for (const key in kats) {
      if (isObjectKey(kats, key) && key !== 'Kat_WasDetail') {
        const checkboxes = kats[key];
        const checkboxObj: Record<string, boolean> = {};

        // Iterate through checkboxes in the current key
        checkboxes.forEach((checkbox) => {
          // Check if the checkbox exists in the angebote data entry
          const isCheckboxChecked =
            data[kats_translations[key] as keyof EditableAngebotInputObject] &&
            data[
              kats_translations[key] as keyof EditableAngebotInputObject
            ].some(
              (item: EditableAngebotInputObject) => item.name === checkbox
            );

          // Assign the result to the checkbox in the new object
          checkboxObj[checkbox] = isCheckboxChecked ? true : false;
        });

        // Assign the checkbox object to the corresponding key in newObj
        checkboxKats[kats_translations[key]] = checkboxObj;
      }
    }
    return checkboxKats;
  };

  const formikValues: FormValues = {
    ...formikInitialValues(),
    openingHours: initialOpeningHours,
    kats: {
      kat_WasDetails: katWasDetailsInitialValues(),
      ...checkBoxKatsInitialValues(),
    },
  };

  const scrollToElement = (name: string) => {
    const element = formRef.current?.elements.namedItem(
      name
    ) as HTMLElement | null;
    if (element) {
      const headerOffset = 75;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({ behavior: 'smooth', top: offsetPosition });
    }
  };

  // validate the fields if they have not been touched yet
  const handleValidateFields = async (formik: any) => {
    try {
      const valid = await formik.validateForm();

      const errorKeys = Object.keys(valid) || [];
      const firstErrorIndex = errorKeys.findIndex(
        (key) => valid[key as keyof FormValues] !== undefined
      );

      // Check if fields are inValid and set them to touched to show errors
      errorKeys.forEach((key) => {
        if (valid[key as keyof FormValues] !== undefined) {
          formik.setFieldTouched(key, true, true);

          // Scroll to first error
          if (firstErrorIndex !== -1) {
            const firstErrorKey = errorKeys[firstErrorIndex];
            scrollToElement(firstErrorKey);
          }
        }
      });

      return Object.keys(valid).length === 0;
    } catch (errors) {
      console.error(errors, 'errors');
      return false;
    }
  };

  const handleCopyFromMonday = useCallback(
    (setValues: (values: SetStateAction<typeof formikValues>) => void) => {
      setValues((prevValues) => {
        const clonedOpeningHours = JSON.parse(
          JSON.stringify(prevValues.openingHours)
        );
        const sundayOpenings = clonedOpeningHours[0].openings.map(
          (opening: { open: string; close: string }) => {
            const { open, close } = opening;
            const formattedOpen = open.length === 5 ? `${open}:00` : open;
            const formattedClose = close.length === 5 ? `${close}:00` : close;

            return {
              open: formattedOpen,
              close: formattedClose,
            };
          }
        );

        clonedOpeningHours.slice(1).forEach((openingHour: any) => {
          // Do not copy to Sunday and Saturday
          if (openingHour.dayOfWeek !== 6 && openingHour.dayOfWeek !== 7) {
            openingHour.openings = JSON.parse(JSON.stringify(sundayOpenings));
          }
        });
        return { ...prevValues, openingHours: clonedOpeningHours };
      });
    },
    []
  );

  if (loading) {
    return (
      <Box>
        <LinearProgress color='success' />
      </Box>
    );
  }

  return (
    <TraegerProvider>
      <>
        <Header data={data} guid={guid} />
        <Formik<FormValues>
          initialValues={formikValues}
          onSubmit={() => {}}
          enableReinitialize
          validate={(values) => validation(values)}
          validateOnChange={false}
        >
          {(formik) => {
            const { values, setValues, handleChange, setFieldValue } = formik;

            const katsValues = values['kats'];
            const wasDetailsValues = katsValues?.kat_WasDetails as string[];

            const katWasDetailsSelect = () => {
              let details: string[] = [];
              Object.keys(katWasUndWasDetails).forEach((keyKat) => {
                if (
                  katsValues?.kat_Was &&
                  katsValues?.kat_Was[keyKat as keyof FormValues['kats']]
                ) {
                  details = details.concat(katWasUndWasDetails[keyKat]);
                }
              });

              return details;
            };

            // This checks if open or close are empty or invalid date
            // and removes that object from the openingHours.opening array
            const filteredArr = values.openingHours?.map((item) => ({
              ...item,
              openings: (
                item as OpeningHours & {
                  openings: { open: string; close: string }[];
                }
              ).openings.filter(
                (opening) =>
                  // remove the any of the opening hour object if open or close is not filled
                  !(
                    opening.open === '' ||
                    opening.open === 'Invalid Date' ||
                    opening.close === '' ||
                    opening.close === 'Invalid Date' ||
                    !opening.close ||
                    !opening.open
                  )
              ),
            }));

            // Flattens the array to match the format of the backend
            const filteredOpeningHours = filteredArr?.flatMap(
              ({ dayOfWeek, openings }) =>
                openings.map(
                  ({ open, close }: { open: string; close: string }) => ({
                    dayOfWeek,
                    open,
                    close,
                  })
                )
            );

            const newKatObject: Record<string, { name: string }[]> = {};

            //reconstruct the kats object to match the backend format for kats submission
            for (const key in katsValues) {
              if (isObjectKey(katsValues, key) && key !== 'kat_WasDetails') {
                newKatObject[key] = [];
                for (const subKey in katsValues[key]) {
                  if (
                    isObjectKey(katsValues[key], subKey) &&
                    katsValues[key][subKey]
                  ) {
                    newKatObject[key].push({ name: subKey });
                  }
                }
              }
            }

            // add the kat_WasDetails to the newObject
            newKatObject['kat_WasDetails'] = katsValues
              ? wasDetailsValues.map((item) => {
                  return { name: item };
                })
              : [];

            // Creating a new values object without the 'kats' key
            const { kats, ...newValues } = values;
            const allowedCheckboxes: Record<string, string> = {};

            return (
              <Form ref={formRef}>
                <Box2
                  // @ts-ignore
                  autoComplete='off'
                  ref={formRef}
                >
                  <MANeueEinrichtungTop
                    data={data}
                    traegerComponent={
                      <TraegerWrap data={data} handleChange={handleTopChange} />
                    }
                  />
                  <Zustaendige
                    data={data}
                    handleChange={handleZustaendigerChange}
                    token={token}
                  />

                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <OpeningHoursPicker
                        handleCopy={() => handleCopyFromMonday(setValues)}
                      />
                    </Grid>

                    {Object.keys(checkBoxKatsInitialValues()).map((key) => {
                      if (
                        isObjectKey(checkBoxKatsInitialValues(), key) &&
                        Object.keys(checkBoxKatsInitialValues()[key]).length > 0
                      ) {
                        const katKey = key as keyof FormValues['kats'];

                        const katGroupValues = values['kats']?.[key] || {};

                        const checkedCount =
                          Object.values(katGroupValues).filter(Boolean).length;

                        return (
                          <Fragment key={key}>
                            <Grid item xs={12} sm={6} lg={4}>
                              <Box1>
                                <Box
                                  component='span'
                                  color={main}
                                  fontSize='1.5rem'
                                >
                                  <span>{KAT_NICE_NAMES[key]}</span>

                                  {key === 'kat_Was' && (
                                    <Tooltip
                                      title={
                                        <Typography>
                                          Erläuterungen zu den Kategorien
                                          findest du im MUT-ATLAS
                                        </Typography>
                                      }
                                      open={openTooltip}
                                      onClose={() => setOpenTooltip(false)}
                                      onOpen={() => setOpenTooltip(true)}
                                    >
                                      <IconButton
                                        onClick={() => setOpenTooltip(true)}
                                        size='small'
                                      >
                                        <InfoOutlinedIcon fontSize='small' />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                                {Object.keys(
                                  checkBoxKatsInitialValues()[key]
                                ).map((item) => {
                                  const checked = values['kats']?.[katKey]
                                    ? // @ts-ignore
                                      values['kats']?.[katKey][item]
                                    : false;

                                  const maxLimit = checkBoxFieldCounts[key];

                                  if (checkedCount >= maxLimit) {
                                    allowedCheckboxes[
                                      katKey
                                    ] = `Maximale ${maxLimit} Anzahl erreicht.`;
                                  }

                                  return (
                                    <div key={item}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={checked}
                                            onChange={(e) => {
                                              handleChange(e);
                                              setFieldValue(
                                                `kats.${key}.${item}`,
                                                e.target.checked
                                              );
                                            }}
                                            disabled={
                                              !checked &&
                                              !!allowedCheckboxes[katKey]
                                            }
                                            name={`kats.${key}.${item}`}
                                          />
                                        }
                                        label={item}
                                      />
                                    </div>
                                  );
                                })}
                                <Typography
                                  variant='subtitle2'
                                  color='primary.info'
                                >
                                  {allowedCheckboxes[katKey]}
                                </Typography>
                              </Box1>
                            </Grid>

                            {/* Show was details after kat_was */}
                            {key === 'kat_Was' && (
                              <Grid item xs={12} sm={6} lg={4}>
                                <Box1>
                                  <MultipleSelect
                                    data={katWasDetailsSelect()}
                                  />
                                </Box1>
                              </Grid>
                            )}
                          </Fragment>
                        );
                      }
                    })}
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: '1em',
                      width: '100%',
                      marginTop: '2em',
                    }}
                  >
                    <BackBtn /> {/* zurück */}
                    <SpeichernBtn
                      token={token}
                      data={{
                        ...data,
                        ...newValues,
                        openingHours: values.einsGeoeffnetDurchgehend
                          ? []
                          : filteredOpeningHours,
                        neueKlientenApi: values.neueKlientenApi ?? '',
                        ...newKatObject,
                      }}
                      operation={guid ? 'edit' : 'create'}
                      handleValidateFields={() => handleValidateFields(formik)}
                      isValid={formik.isValid}
                    />
                  </Box>
                </Box2>
              </Form>
            );
          }}
        </Formik>
        <Snackbar
          open={snackbarctx.open}
          autoHideDuration={2000}
          onClose={snackbarctx.handleClose}
          message={snackbarctx.message}
          action={
            <Box>
              <Button
                color='secondary'
                size='small'
                onClick={(e) => snackbarctx.handleClose(e, 'clickaway')}
              >
                Schliessen
              </Button>
              {/* @ts-ignore */}
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={snackbarctx.handleClose}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </Box>
          }
        />
      </>
    </TraegerProvider>
  );
}
