export function validation(formikValues: any) {
  const {
    email,
    hausnummer,
    plz,
    postfachPLZ,
    postfachNr,
    zusatz,
    telefon,
    homePage,
    strasse,
    name,
    isWebsiteUnavailable,
  } = formikValues;

  const errors: Partial<typeof formikValues> &
    Partial<{ postfachPLZ: string }> = {};

  if (!name) {
    errors.name = 'Benötigt';
  }

  if (!strasse) {
    errors.strasse = 'Benötigt';
  }

  if (!email && !telefon) {
    errors.email = 'Benötigt (oder Telefon)';
  } else if (
    !!email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  ) {
    errors.email = 'Ungültige E-Mail Adresse';
  }
  if (!hausnummer) {
    errors.hausnummer = 'Benötigt';
  }
  if (hausnummer && !/^[0-9].*/.test(hausnummer)) {
    errors.hausnummer = 'Ungültige Hausnummer';
  }

  if (!plz) {
    errors.plz = 'Benötigt';
  } else if (!/^[0-9]{5}$/.test(plz.trim())) {
    errors.plz = 'Ungültige PLZ: max. 5 Ziffern, keine Buchstaben.';
  }

  if (postfachPLZ?.length ? !/^[0-9]{0,7}$/.test(postfachPLZ) : false) {
    errors.postfachPLZ = 'Ungültige PLZ: kein Leerzeichen, max. 5 Ziffern';
  }

  if (postfachNr?.length ? !/^[0-9]{0,7}/.test(postfachNr) : false) {
    errors.postfachNr = 'Ungültige Nummer';
  }

  if (zusatz?.length && !/^.{0,20}$/.test(zusatz)) {
    errors.zusatz = 'Ungültige Zusatz, maximale Länge: 20 Zeichen';
  }

  if (!telefon && !email) {
    errors.telefon = 'Benötigt (oder E-Mail)';
  } else if (!!telefon && !/^\+*(\d.*){3,}[\s/-]?\d.*/.test(telefon)) {
    errors.telefon = 'Ungültige Telefon Nummer';
  }

  if (!isWebsiteUnavailable && !homePage?.length) {
    errors.homePage =
      "Benötigt, wenn 'Website nicht verfügbar' nicht ausgewählt ist";
  }

  if (
    !isWebsiteUnavailable &&
    homePage?.length &&
    !/^(http(s)?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/gi.test(
      homePage
    )
  ) {
    errors.homePage = 'Ungültige URL. z.B.: http://www.beispiel.com';
  }

  return errors;
}
