import React, { Fragment, memo } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Box1 } from '../MAEinrichtung/MAEinrichtung-Styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export interface OpeningHoursForm {
  dayOfWeek: number;
  openings: {
    open: string;
    close: string;
  }[];
}
interface Props {
  handleCopy: () => void;
}

const weekdays: Record<number, string> = {
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag',
  7: 'Sonntag',
};

const OpeningHoursPicker = ({ handleCopy }: Props) => {
  const { values, setFieldValue, handleChange }: any = useFormikContext();

  return (
    <Box1>
      <Typography variant='h1' sx={{ marginBottom: '1rem' }}>
        Öffnungszeiten
      </Typography>

      <FormGroup sx={{ gap: 2 }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: 5,
            cursor: 'pointer',
            marginBottom: '1em',
          }}
          component={'label'}
        >
          <Checkbox
            name='einsGeoeffnetDurchgehend'
            checked={values['einsGeoeffnetDurchgehend'] ?? false}
            onChange={(e) => {
              handleChange(e);
            }}
            sx={{ padding: '0', marginRight: '.5em' }}
          />
          <Typography component='span'>durchgehend geöffnet</Typography>
        </Box>

        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            gridColumnStart: 6,
            gridColumnEnd: 10,
            cursor: 'pointer',
            marginBottom: '1em',
          }}
          component={'label'}
        >
          <Checkbox
            name='einsGeoeffnetNachVereinbarung'
            checked={values['einsGeoeffnetNachVereinbarung'] ?? false}
            onChange={(e) => {
              handleChange(e);
            }}
            sx={{ padding: '0', marginRight: '.5em' }}
          />
          <Typography component='span'>
            (zusätzliche) Termine nach Vereinbarung
          </Typography>
        </Box>

        <Box display='flex'>
          <Box minWidth={100}></Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={3}
              sm={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Öffnungszeit 1
            </Grid>
            <Grid
              item
              xs={3}
              sm={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Öffnungszeit 2 (falls es z.B. eine Mittagspause gibt)
            </Grid>
          </Grid>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FieldArray name='openingHours'>
            {() => (
              <>
                {values.openingHours.map((openingHour: any, dayIndex: any) => (
                  <Fragment key={dayIndex}>
                    <Box display='flex'>
                      <Box minWidth={100}>
                        {weekdays[openingHour.dayOfWeek]}
                      </Box>
                      <Grid container spacing={2}>
                        <FieldArray name={`openingHours.${dayIndex}.openings`}>
                          {() =>
                            openingHour.openings.map(
                              (opening: any, openingIndex: number) => (
                                <Fragment key={`${openingIndex}-${dayIndex}`}>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Field
                                      as={TimePicker}
                                      ampm={false}
                                      name={`openingHours.${dayIndex}.openings.${openingIndex}.open`}
                                      value={
                                        dayjs(opening.open, 'HH:mm').isValid()
                                          ? dayjs(opening.open, 'HH:mm')
                                          : null
                                      }
                                      onChange={(value: any) => {
                                        const newOpeningHours = [
                                          ...values.openingHours,
                                        ];
                                        newOpeningHours[dayIndex].openings[
                                          openingIndex
                                        ].open = value?.format('HH:mm');

                                        setFieldValue(
                                          'openingHours',
                                          newOpeningHours,
                                          true
                                        );
                                      }}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          variant='outlined'
                                        />
                                      )}
                                      label='Von'
                                      variant='outlined'
                                      disabled={values.einsGeoeffnetDurchgehend}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Field
                                      as={TimePicker}
                                      ampm={false}
                                      value={
                                        dayjs(opening.close, 'HH:mm').isValid()
                                          ? dayjs(opening.close, 'HH:mm')
                                          : null
                                      }
                                      onChange={(value: any) => {
                                        const newOpeningHours = [
                                          ...values.openingHours,
                                        ];
                                        newOpeningHours[dayIndex].openings[
                                          openingIndex
                                        ].close = value?.format('HH:mm');

                                        setFieldValue(
                                          'openingHours',
                                          newOpeningHours
                                        );
                                      }}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          variant='outlined'
                                        />
                                      )}
                                      type='text'
                                      name={`openingHours.${dayIndex}.openings.${openingIndex}.close`}
                                      label='Bis'
                                      placeholder='Bis'
                                      variant='outlined'
                                      disabled={values.einsGeoeffnetDurchgehend}
                                    />
                                  </Grid>
                                </Fragment>
                              )
                            )
                          }
                        </FieldArray>
                      </Grid>
                    </Box>

                    {dayIndex === 0 && (
                      <Box>
                        <Button
                          type='button'
                          variant='outlined'
                          onClick={() => handleCopy()}
                          disabled={values.einsGeoeffnetDurchgehend}
                        >
                          <Typography component='span' mr={1}>
                            Offnungszeiten von Montag Kopieren
                          </Typography>

                          <ContentCopyIcon fontSize='small' />
                        </Button>
                      </Box>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          </FieldArray>
        </LocalizationProvider>
      </FormGroup>
    </Box1>
  );
};

export default memo(OpeningHoursPicker);
