const _load_multifieldcounts = () => {
  // write multifieldcounts into local storage for use in form Validation later on

  fetch(process.env.REACT_APP_API_URL + 'AngeboteDoc/multifieldcounts', {
    cache: 'force-cache',
  }).then((resp) => {
    if (resp.ok)
      resp.json().then((json) => {
        // transform json
        const multifieldcounts = Object.assign(
          {},
          ...json.map((item: { [key: string]: string }) => {
            return { [item.key]: item.value };
          })
        );

        localStorage.setItem(
          'multifieldcounts',
          JSON.stringify(multifieldcounts)
        );
      });
  });
};
export default _load_multifieldcounts;
