import TextField from '@mui/material/TextField';
import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@components/search/useDebounce';
import { getData, postData } from '@components/_api';
import Snackbar from '@mui/material/Snackbar';

import { ITraeger } from '../../../../../interfaces';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTraegerContext } from '../../../../../context/TraegerContext';

export const Traeger = ({
  handleChange,
  token,
  refLfNrTraeger,
}: {
  handleChange: (ev: SelectChangeEvent<string>, name: string) => void;
  token: string;
  refLfNrTraeger: string | undefined;
}) => {
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 300);
  const [data, setData] = useState<ITraeger[]>([]);
  const { setData: setTraegerData } = useTraegerContext();

  const [selectedOption, setSelectedOption] = useState('');

  const getTraegerData = useCallback(
    async (refLfNrTraeger?: string) => {
      return getData(
        'AngeboteAuth/gettraegerfromlfnr/' + refLfNrTraeger,
        token
      ).then((resp) => resp);
    },
    [token]
  );

  useEffect(() => {
    if (!refLfNrTraeger) return;

    async function getData() {
      const resp = await getTraegerData(refLfNrTraeger);
      if (resp.status === 200 && resp.data !== 0) {
        setSelectedOption(resp.data.firma);
        setTraegerData?.(resp.data);
      } else {
        setSelectedOption('');
      }
    }

    getData();

    const fakeEvent = { target: { value: refLfNrTraeger ?? '' } };
    handleChange(fakeEvent as SelectChangeEvent, 'refLfNrTraeger');
  }, [refLfNrTraeger, getTraegerData, refLfNrTraeger]);

  const onChange = async (ev: SelectChangeEvent<string>) => {
    const s = data.find((item) => item.firmaNr === ev.target.value);
    const resp = await getTraegerData(ev.target.value);
    setTraegerData(resp.data);
    if (s) {
      handleChange(ev, 'refLfNrTraeger');
      setSelectedOption(s.firma);
    }
  };

  useEffect(() => {
    const json = {
      search: debouncedValue,
    };
    if (debouncedValue.length > 0)
      postData('AngeboteAuth/gettraeger', json, token).then((resp) => {
        if (resp.status === 200) {
          if (resp.data !== 0) {
            setData(resp.data);
          } else {
            setOpen(true);
          }
        }
      });
  }, [debouncedValue]);

  const [open, setOpen] = React.useState(false);
  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color='secondary' size='small' onClick={handleSnackbarClose}>
        Schliessen
      </Button>
      <IconButton
        size='small'
        aria-label='close'
        color='error'
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  );

  // https://codesandbox.io/s/react-mui-searchable-select-nm3vw?file=/src/App.js
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={'Bei der Suche ist ein Fehler aufgetreten..'}
        action={action}
      />

      <FormControl fullWidth>
        <InputLabel id='search-select-label'>Träger</InputLabel>
        <Select
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{ autoFocus: false }}
          labelId='search-select-label'
          id='search-select'
          value={selectedOption}
          label='Träger'
          onChange={(e) => onChange(e)}
          onClose={() => setValue('')}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          renderValue={() => selectedOption}
          sx={{ padding: ' 9px 14px' }}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size='small'
              // Autofocus on textfield
              autoFocus
              placeholder='Träger suchen...'
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {data.map((option, i) => (
            <MenuItem key={i} value={option.firmaNr}>
              {option.firma}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
