import { useState, useEffect, useReducer } from 'react';
import { getData } from '@components/_api';
import {
  Kat_Was_und_WasDetails,
  buildKats,
} from '@components/dashboard-ma/MAEinrichtung/filter-categories';

export default function useGetCategories() {
  const [error, setError] = useState<string | null>(null);

  const [katWasUndWasDetails, setKatUndKatWasDetails] =
    useState<Kat_Was_und_WasDetails>({});

  const [kats, setKats1] = useReducer(
    (
      state: { [key: string]: string[] },
      newState: { [key: string]: string[] }
    ) => ({
      ...state,
      ...newState,
    }),
    {}
  );

  useEffect(() => {
    (async () => {
      try {
        const resp = await getData('angebotedoc/werte');
        if (resp.status === 200 && resp.data !== 0) {
          const [group, kwuw] = buildKats(resp.data);
          setKats1({
            ...group,
          });

          setKatUndKatWasDetails(kwuw);
        } else {
          console.error('Could not get Werteliste');
        }
      } catch (error) {
        setError('Could not get Werteliste');
        console.error(`Could not get Werteliste - ${error}`);
      }
    })();
  }, []);

  return { kats, katWasUndWasDetails, error };
}
