import { EditableAngebotInputObject } from '../../interfaces';

const now = new Date();
const empty_data: EditableAngebotInputObject = {
  autoLfNr: '',
  name: '',
  niceName: '',
  art: '',
  typ: '',
  strasse: '',
  hausnummer: '',
  zusatz: '',
  plz: '',
  ort: '',
  postfachPLZ: '',
  postfachNr: '',
  datum: now.toISOString(),
  fax: '',
  telefon: '',
  email: '',
  homePage: '',
  beschreibung: '',
  /* zielgruppen: '', */
  bemerkungen: '',
  einsGeoeffnetDurchgehend: false,
  einsGeoeffnetNachVereinbarung: false,
  einsKeineNeuenKlienten: false,
  einsKeinTraeger: false,
  einsDeutschlandweit: false,
  oeffnungszeiten: '',
  arbeitszeiten: '',
  landkreis: '',
  refLfNrTraeger: '',
  latitude: 0,
  longitude: 0,
  kat_verfuegbarkeit: '',
  themen: [],
  openingHours: [],
  kat_Was: [],
  kat_WasDetails: [],
  kat_Konstellationen: [],
  kat_Haeufigkeiten: [],
  kat_Orte: [],
  kat_Geschlechter: [],
  /* kat_PersonRollen: [],
  kat_PersonAlter: [], */
  kat_Zielgruppe: [],
  kat_Barrieren: [],
  kat_Kosten: [],
  kat_Diagnosen: [],
  kat_Themen: [],
  kat_Sprachen: [],
  KatWasSum: '',
};

export { empty_data };
