import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Angebot, Person } from '../../../../interfaces';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Box1 } from '@components/dashboard-ma/MAEinrichtung/MAEinrichtung-Styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CreateZustaendigenModal from './components/CreateZustaendigenModal';

export default function Zustaendige({
  handleChange,
  data,
  token,
}: {
  handleChange: (persons: Person[]) => void;
  data: Angebot;
  token: string;
}) {
  const [persons, setPersons] = React.useState<Person[] | []>(
    data.angebotePos ?? []
  );
  const [zustandigeModal, setZustandigeModal] = React.useState({
    open: false,
    editId: 'new',
  });
  const { editId, open } = zustandigeModal;

  const [openTooltip, setOpenTooltip] = React.useState(false);

  const changePerson = (p: Person, type: 'add' | 'edit') => {
    if (type === 'edit') {
      const removeOldPerson = persons.filter(
        (person) =>
          person.angebotAdresse.lfNrAdresse !== p.angebotAdresse.lfNrAdresse
      );

      setPersons([...removeOldPerson, p]);
    } else {
      setPersons((prev) => [...prev, p]);
    }
  };

  const handleDelete = (lfNrAdresse: string | undefined) => {
    setPersons((prev) =>
      prev?.filter((item) => item.angebotAdresse?.lfNrAdresse !== lfNrAdresse)
    );
  };

  useEffect(() => {
    handleChange(persons);
  }, [persons]);

  return (
    <Box sx={{ mb: '2em' }}>
      <Box1>
        <Typography variant='h1'>
          Zuständige Personen{' '}
          <Tooltip
            title={
              <Typography>
                Bei Psychiatrien, Krankenhäusern und anderen stationären
                Angeboten bitte keine konkreten Behandler eingeben, sondern
                lieber Zentralen
              </Typography>
            }
            open={openTooltip}
            onOpen={() => setOpenTooltip(true)}
            onClose={() => setOpenTooltip(false)}
          >
            <IconButton onClick={() => setOpenTooltip(true)}>
              <InfoOutlinedIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1.5em',
            mb: '2em',
            mt: '2em',
          }}
        >
          {persons?.map(({ angebotAdresse, funktion }, i) => {
            const {
              vorname,
              lfNrAdresse,
              nachname,
              handy,
              telefon1,
              eMail,
            } = angebotAdresse || ({} as Person['angebotAdresse']);
            return (
              <Box
                key={i}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                boxShadow={1}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '5px',
                  // border: '1px solid #f1f1f1 ',
                  padding: '1rem',
                  '& div': { color: 'inherit', fontWeight: 'normal' },
                  '& div:last-of-type': { mb: '1em' },
                  '& .mail': { mt: '.3em' },
                  '& address': {
                    fontStyle: 'inherit',
                    fontWeight: 'normal',
                    mt: '.3em',
                  },
                }}
              >
                <Box>
                  <div>
                    {vorname} {nachname}
                  </div>
                  <div className={'function'}>
                    <strong>Funktion</strong>: {funktion}
                  </div>
                  <div className={'mail'}>{eMail}</div>
                  <div>Tel: {telefon1}</div>
                  <div>Mobil: {handy}</div>
                </Box>
                <Box display='flex' gap={2} alignSelf='end'>
                  {!!lfNrAdresse && (
                    <Button
                      onClick={() =>
                        setZustandigeModal({
                          editId: lfNrAdresse,
                          open: true,
                        })
                      }
                      centerRipple={true}
                      variant='outlined'
                    >
                      Bearbeiten
                    </Button>
                  )}

                  <Button
                    onClick={() => handleDelete(angebotAdresse?.lfNrAdresse)}
                    centerRipple={true}
                    color='error'
                    variant='outlined'
                  >
                    Löschen
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box>
          <CreateZustaendigenModal
            open={open}
            editId={editId}
            handleClose={() => setZustandigeModal({ editId: '', open: false })}
            changePerson={changePerson}
            token={token}
            data={data}
          />

          <Button
            onClick={() => setZustandigeModal({ editId: '', open: true })}
          >
            Zuständige Person hinzufügen
          </Button>
        </Box>
      </Box1>
    </Box>
  );
}
