import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Formik } from 'formik';
import { fieldsConfig } from './fields';
import {
  error,
  style,
} from '../../Traeger/components/CreateTraegerModal_Styles';
import { Angebot, Person } from '../../../../../interfaces';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InputTooltip } from '@components/common/InputTooltip';
import { postData } from '@components/_api';
import { useSnackbarContext } from '../../../../../context/SnackBarContext';

export default function CreateZustaendigenModal({
  open,
  handleClose,

  data,
  editId,
  token,
  changePerson,
}: {
  open: boolean;
  handleClose: () => void;
  changePerson: (person: Person, type: 'edit' | 'add') => void;
  data: Angebot;
  editId: string;
  token: string;
  // editPerson: () => void;
}) {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<{
    [key: string]: boolean;
  }>({ name: false });

  const personToEdit = data.angebotePos?.find(
    /* TODO: Leon: ich habe bei angebotAdresse das ? hinzugefügt. Aber eigentlcih sollte es angebotAdresse immer geben?? 
    Wahrscheinlich ist das nur ein Problem auf DEV mit Api v1
    lfNrAdresse könnte fehlen, weil die Daten ja in die Tabelle angebotePos kopiert wurden */
    ({ angebotAdresse }) => angebotAdresse?.lfNrAdresse === editId
  );

  const { setMessage, setOpen } = useSnackbarContext();

  const handleFormSubmit = async (values: TInitData) => {
    if (!!editId) {
      try {
        postData(
          'AngeboteAuth/editangebotadresse',
          {
            ...values,
            lfNrAdresse: personToEdit?.angebotAdresse.lfNrAdresse,
          },
          token
        ).then((resp) => {
          if (resp.status === 200) {
            changePerson(
              {
                ...personToEdit,
                funktion: values.funktion,
                angebotAdresse: { ...personToEdit?.angebotAdresse, ...values },
              },
              'edit'
            );

            setOpen(true);
            setMessage('Gespeichert');
            handleClose();
          }
        });
      } catch (error) {
        setOpen(true);
        setMessage('Ein Fehler ist aufgetreten.');
        console.error(error, 'errror');
      }
    } else {
      changePerson(
        {
          funktion: values.funktion,
          //lfNrAdress: resp.data.lfNrAdresse, // kein typo hier, ist so.
          lfNrAngebote: '',
          angebotAdresse: values,
        },
        'add'
      );

      handleClose();
    }
  };

  const { angebotAdresse, funktion } = personToEdit || ({} as Person);

  const {
    nachname,
    telefon1,
    eMail,
    handy,
    vorname,
  } = angebotAdresse || ({} as Person['angebotAdresse']);

  const initFormData = {
    funktion: funktion ?? '',
    vorname: vorname ?? '',
    nachname: nachname ?? '',
    telefon1: telefon1 ?? '',
    eMail: eMail ?? '',
    handy: handy ?? '',
  };

  type TInitData = typeof initFormData;

  const validate = (values: TInitData) => {
    const errors: Partial<TInitData> = {};

    if (!values.funktion) errors.funktion = 'Benötigt';
    if (!values.vorname) errors.vorname = 'Benötigt';
    if (!values.nachname) errors.nachname = 'Benötigt';

    if (
      values.eMail.length > 0 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.eMail)
    )
      errors.eMail = 'Ungültige Email-Addresse';

    if (
      values.telefon1.length > 0 &&
      !/^\+*(\d.*){3,}[\s/-]?\d.*/.test(values.telefon1)
    )
      errors.telefon1 = 'Ungültige Telefon Nummer';

    if (values.telefon1.length > 0 && data.telefon === values.telefon1)
      errors.telefon1 =
        'Telefon Nummer identisch mit der Telefonnr. im Angebot';

    return errors;
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div>
            <div>
              <Typography
                variant='h2'
                sx={{
                  marginBottom: '2rem',
                }}
              >
                Zuständige Person hinzufügen
              </Typography>
            </div>
            <div>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initFormData}
                validate={validate}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                      columns={{ xs: 4, sm: 8, md: 16 }}
                    >
                      {fieldsConfig &&
                        fieldsConfig.map(({ name, label, type, tooltip }) => {
                          const nameKey = name as keyof TInitData;
                          return (
                            <Grid item xs={8} pt={0} key={label}>
                              <InputTooltip
                                name={nameKey}
                                tooltip={tooltip}
                                open={isTooltipOpen[nameKey] ?? false}
                                handleOpen={() =>
                                  setIsTooltipOpen({
                                    [nameKey]: true,
                                  })
                                }
                                handleClose={() =>
                                  setIsTooltipOpen({
                                    [nameKey]: false,
                                  })
                                }
                              >
                                <TextField
                                  sx={{
                                    width: '100%',
                                  }}
                                  key={label}
                                  InputLabelProps={{ shrink: !!label }}
                                  type={type}
                                  id={name}
                                  label={label}
                                  variant='outlined'
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  value={values[nameKey]}
                                  placeholder={label}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        {tooltip && (
                                          <InfoOutlinedIcon
                                            fontSize='small'
                                            onClick={() => {
                                              setIsTooltipOpen({
                                                [nameKey]: true,
                                              });
                                            }}
                                          />
                                        )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </InputTooltip>
                              {errors &&
                                errors[nameKey] &&
                                touched[nameKey] && (
                                  <Box sx={error}>{errors[nameKey]}</Box>
                                )}
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        mt: '2em',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        color={'error'}
                        onClick={handleClose}
                        variant='contained'
                      >
                        Abbrechen
                      </Button>
                      <Button
                        type={'submit'}
                        variant='contained'
                        disabled={Object.keys(errors).length > 0}
                      >
                        {editId ? 'Speichern' : 'Hinzufügen'}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
